import React, {Component} from 'react';
import {Button, Col, Drawer, Image, Layout, Menu, Row} from "antd";
import Scrollspy from "react-scrollspy";
import {IsDesktopOrLaptop, IsTabletOrMobile} from "../../hoc/MediaQueries/ComponentMediaQuery";
import {MenuOutlined} from "@ant-design/icons";

interface IMainLayoutProps {
}


interface IMainLayoutStates {
    activeHeader: string;
    activeSidebar: boolean;
}

class MainLayout extends Component<IMainLayoutProps, IMainLayoutStates> {
    constructor(props: IMainLayoutProps) {
        super(props);

        this.state = {
            activeHeader: "dashboard",
            activeSidebar: false,
        }
    }

    onUpdate = (id: string) => {
        console.log(id)
        this.setState({
            activeHeader: id,
        });
    }

    toggleSidebar = () => {
        this.setState({
            activeSidebar: !this.state.activeSidebar,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Scrollspy componentTag={'div'}
                           items={['dashboard', 'games', 'about', 'contribution', 'tokenomic']}
                           currentClassName={'active'}
                           onUpdate={(item) => this.onUpdate(item.id)}
                />
                <Layout id={'layout'}>
                    <Layout.Header className={`desktop-header`}>
                        <Row className={'navbar-wrapper'}>
                            <Col span={12}>
                                <Image src={'assets/img/n3ftlogo.png'} height={60} preview={false}/>
                            </Col>
                            <Col span={12}>
                                <IsDesktopOrLaptop>
                                    <Menu className={"menu-bar"} theme={'dark'} mode="horizontal"
                                          activeKey={this.state.activeHeader}
                                          selectable={false}>
                                        <Menu.Item key={"dashboard"}>
                                            <a href={"#dashboard"}>HOME</a>
                                        </Menu.Item>
                                        <Menu.Item key={"games"}>
                                            <a href={"#games"}>GAMES</a>
                                        </Menu.Item>
                                        <Menu.Item key={"about"}>
                                            <a href={"#about"}>ABOUT</a>
                                        </Menu.Item>
                                        <Menu.Item key={"contribution"}>
                                            <a href={"#contribution"}>CONTRIBUTION</a>
                                        </Menu.Item>
                                        <Menu.Item key={"tokenomic"}>
                                            <a href={"#tokenomic"}>TOKENOMIC</a>
                                        </Menu.Item>
                                    </Menu>
                                </IsDesktopOrLaptop>
                                <IsTabletOrMobile>
                                    <Button onClick={this.toggleSidebar} className={'button-menu'}
                                            icon={<MenuOutlined/>}
                                            size={'large'}/>
                                </IsTabletOrMobile>
                            </Col>
                        </Row>
                    </Layout.Header>

                    <Layout.Content className={'landing-body'}>
                        <IsTabletOrMobile>
                            <Drawer className={'menu-drawer'} title="MENU" placement="right"
                                    visible={this.state.activeSidebar}
                                    onClose={this.toggleSidebar}
                                    width={320}

                            >
                                <Menu theme={"dark"} activeKey={this.state.activeHeader}
                                      className={"menu-side"}
                                >
                                    <Menu.Item key={"dashboard"}>
                                        <a href={"#dashboard"}>HOME</a>
                                    </Menu.Item>
                                    <Menu.Item key={"games"}>
                                        <a href={"#games"}>GAMES</a>
                                    </Menu.Item>
                                    <Menu.Item key={"about"}>
                                        <a href={"#about"}>ABOUT</a>
                                    </Menu.Item>
                                    <Menu.Item key={"contribution"}>
                                        <a href={"#contribution"}>CONTRIBUTION</a>
                                    </Menu.Item>
                                    <Menu.Item key={"tokenomic"}>
                                        <a href={"#tokenomic"}>TOKENOMIC</a>
                                    </Menu.Item>
                                </Menu>
                            </Drawer>
                        </IsTabletOrMobile>

                        {this.props.children}
                    </Layout.Content>
                    <Layout.Footer className={'main-footer'}>
                        Copyright © 2022. All Rights Reserved By N3FT
                    </Layout.Footer>
                </Layout>
            </React.Fragment>
        );
    }
}

export default MainLayout;