import React, {Component} from 'react';
import {Col, Image, Row} from "antd";
import "./Dashboard.less"

class Dashboard extends Component {
    render() {
        return (
            <Row className={'dashboard-section'} align={'middle'}>
                <Col xs={24} sm={12} lg={12} md={24} xl={12} className={`body-left`}>
                    <div className={`landing-title1-dashboard`}>ALL IN ONE</div>
                    <div className={`landing-title2-dashboard`}>NFT Game Asset Management<br/>System</div>
                    <ul id={"list-features"}>
                        <li>
                            <Image preview={false} src={'assets/img/Asset_2.png'}/>
                            <div>
                                <b>Asset Management</b><br/>
                                Monitor game nft asset from many different games and across
                                blockchain.
                            </div>
                        </li>
                        <li>
                            <Image preview={false} src={'assets/img/Asset_4.png'}/>
                            <div>
                                <b>Earning Management</b><br/>
                                Monitor your NFT game earning from co-op with scholars or rental
                                agreement.
                            </div>

                        </li>
                        <li>
                            <Image preview={false} src={'assets/img/Asset_5.png'}/>
                            <div>
                                <b>Risk Management</b> <br/>
                                Monitor the changing landscape of cryptocurrency and NFT games.
                            </div>
                        </li>
                    </ul>
                </Col>
                <Col xl={12} lg={12} id={"dashboard-image"}>
                    <Image preview={false} src={'assets/img/track-yours.png'}
                           alt={`n3ft-assets`}/>
                </Col>
            </Row>
        );
    }
}

export default Dashboard;
