import React, {Component} from 'react';
import {Col, Row} from "antd";
import TokenomicPie from "./TokenomicPie";
import "./Tokenomic.less";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiscord} from '@fortawesome/free-brands-svg-icons'

class Tokenomic extends Component {
    render() {
        return (
            <React.Fragment>
                <Row align={"middle"} id={"tokenomic-section"}>
                    <Col lg={12} sm={24}>
                        <div id={'tokenomic-sub-head'}>
                            TOKENOMIC
                        </div>
                        <div id={'tokenomic-title'}>
                            <span>Fundraiser Result</span><br/>
                        </div>

                        <table cellPadding={0} cellSpacing={0} id={"tokenomic-table"}>
                            <thead>
                            <tr>
                                <th>Participant</th>
                                <th>Unit Sold %</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Orginator</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>Project Leader</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>Art Manager</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Early Birds</td>
                                <td>25</td>
                            </tr>
                            <tr>
                                <td>Contributors</td>
                                <td>62</td>
                            </tr>
                            </tbody>
                        </table>
                        <div id={"tokenomic-description"}>
                            <b>Early Birds :</b>
                            <br/>
                            <span>
                                250,000 $N3FT @ 5 ETH (230,000,000 IDR) Unsold $N3FT will be distributed to contributor.
                            </span>
                            <br/>
                            <b>
                                All profit (revenue-expenses) will distributed among token holders
                            </b>
                            <div style={{marginTop: "15px"}}>
                                <a id={"join-our-discord-link"} href={"https://discord.gg/34qdcERz"} target={"_blank"}>
                                    <FontAwesomeIcon icon={faDiscord}/> JOIN OUR DISCORD
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} sm={24}>
                        <TokenomicPie/>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Tokenomic;