import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, Col, Divider, Form, Input, Layout, Row, Space, Typography} from "antd";
import "./SignIn.less";
import {GoogleOutlined} from "@ant-design/icons";
import Language from "../../components/Language/Language";

class SignIn extends Component {
    render() {
        return (
            <Layout>
                <Layout.Content>
                    <div id={"sign-up-body"}>
                        <Row id="sign-up-wrapper">
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-left-side"}>

                                <Link to={`/`}>
                                    <img className={`header-logo`} src={'assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Link>

                                <div id={"text-left-side-wrapper"}>
                                    <Typography.Title level={1}>
                                        Daily tracker for your NFT games asset.
                                    </Typography.Title>
                                </div>
                                <br/>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-right-side"}>
                                <div id={"sign-up-link"}>
                                    <Typography.Text style={{color: "#7A86A1"}}>Didn't have account?</Typography.Text>
                                    <Link to={`/sign-up`}><Typography.Link> Sign Up here</Typography.Link></Link>
                                </div>
                                <div id={"form-wrapper"}>
                                    <Typography.Title level={2}>
                                        Sign In
                                    </Typography.Title>
                                    <Space id={"button-signup-third-party"}>
                                        <Button className={'signup-button'} icon={<GoogleOutlined/>}>Sign In with Google</Button>
                                        <Link to={`/guest`}><Typography.Link> <Button className={'signup-button-white'}>Use as Guest</Button></Typography.Link></Link>
                                    </Space>
                                    <Divider style={{
                                        borderTop: "1px solid rgba(255,255,255, .5)",
                                        minWidth: "auto",
                                        width: "50px"
                                    }}/>
                                    <Typography.Text style={{color: "#7A86A1"}}>
                                        Or sign in using your email address
                                    </Typography.Text>
                                    <Form layout={'vertical'} size={'large'}>
                                        <Row gutter={[16, 0]}>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Email'}>
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Password'}>
                                                    <Input.Password/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Checkbox>Remember Me <Typography.Link>Forget Password</Typography.Link></Checkbox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button className={'signup-button'}>
                                                    Login
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
                <Layout.Footer className={'main-footer'}>
                    <Language/>
                </Layout.Footer>
            </Layout>
        );
    }
}

export default SignIn;
