import React, {Component} from 'react';
import MainLayout from "../../components/LandingLayout/MainLayout";
import Dashboard from "../Dashboard/Dashboard";
import Games from "../Games/Games";
import Tokenomic from "../Tokenomic/Tokenomic";
import About from "../About/About";
import Contribution from "../Contribution/Contribution";

class MainLanding extends Component {
    render() {
        return (
            <MainLayout>
                <section id={"dashboard"} className="section-fullpage">
                    <Dashboard/>
                </section>
                <section id={"games"} className={'section-fullpage'}>
                    <Games/>
                </section>
                <section id={"about"} className={'section-fullpage'}>
                    <About/>
                </section>
                <section id={"contribution"} className={'section-fullpage'}>
                    <Contribution/>
                </section>
                <section id={"tokenomic"} className="section-fullpage">
                    <Tokenomic/>
                </section>
            </MainLayout>
        );
    }
}

export default MainLanding;