import React, {Component} from 'react';
import {Button, Col, Image, Row, Typography} from "antd";
import "./Contribution.less";

class Contribution extends Component {
    render() {
        return (
            <React.Fragment>
                <Row id={"contribution-section"} align={'middle'}>

                    <Col xl={12} lg={12} sm={24}>
                        <div id={"contribution-title"}>
                            Be Part Of Us
                        </div>
                        <div id={"contribution-description"}>
                            By using decentralized blockchain, any contribution made will be recorded and contributors
                            will earn N3FT tokens. Our N3FT tokens are reserved only for contributors which means the
                            only way you can earn is by contributing to our project.
                        </div>
                        <div id={"contribution-link"}>
                            <Typography.Text>
                                Click the link below
                            </Typography.Text>
                        </div>
                        <div id={"contribution-button"}>
                            <Button onClick={() => {
                                window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSePo282A7W6QqYgVVeLXxv897YsqHdg43JTaW7Wgbp10zl64Q/viewform"
                            }}
                                    id={"contribution-signup-button"}>
                                CLICK HERE
                            </Button>
                        </div>
                    </Col>

                    <Col xl={12} lg={12} id={"contribution-image"}>
                        <Image preview={false} src={'assets/img/3D00606 1.png'}
                               alt={`n3ft-assets`}/>
                    </Col>

                </Row>
            </React.Fragment>
        );
    }
}

export default Contribution;