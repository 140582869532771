import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import MainLayout from "../../components/MainLayout/MainLayout";
import "./MyNftAssets.css";
import {Col, Row} from "antd";
import {axieDataService} from "../../services/axieinfinity/AxieDataService";
import {roninChainService} from "../../services/axieinfinity/RoninChainService";
import {TokenHoldingVertical} from "../../components/Tracker/TokenHoldingVertical";
import {AxieHolding} from "../../components/Tracker/AxieHolding";

interface IParams {
    roninAddress: string;
}

interface IMyNftAssetsStates {
    processing: boolean;
    roninAddress: string;
    dataAxie: any;
    dataTokenHoldings: any;
}

type Props = WithRouterProps<IParams>;
document.title = "N3FT.GAMES - My Assets";

class MyNftAssets extends Component<Props, IMyNftAssetsStates> {
    private readonly initState!: IMyNftAssetsStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;

        this.initState = {
            processing: true,
            roninAddress: match.params.roninAddress,
            dataAxie: [],
            dataTokenHoldings: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.getListAxie();
        await this.getTokenHoldings();
    }

    async getListAxie() {
        try {
            await axieDataService.getListAxieByAddress({
                roninAddress: this.state?.roninAddress
            }).then((r) => {
                this.setState({
                    processing: false,
                    dataAxie: r?.data
                })
            });
        } catch (e) {
            console.log(e)
        }
    }

    async getTokenHoldings() {
        try {
            await roninChainService.getTokenHoldings({
                roninAddress: this.state?.roninAddress
            }).then((r) => {
                // this.setState({
                //     processing: false,
                //     dataTokenHoldings: r?.data
                // })
            });
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <MainLayout defaultSelectedKeys={['m-tracker']}>

                    <h2 className={`h2Title`}>My NFT Assets</h2>

                    <Row>
                        <Col xs={24} xl={6}>
                            <TokenHoldingVertical/>
                        </Col>
                        <Col xs={24} xl={18}>
                            <Row style={{marginTop: 14}}>
                                {
                                    this.state.dataAxie.map((item: any, i: number) =>
                                        <Col xs={24} xl={6} key={i}>
                                            <div className={`boxListProduct2`}>
                                                <a href={`https://marketplace.axieinfinity.com/axie/${item?.id}/`} target={`_blank`} style={{textDecoration: "none"}}>
                                                    <AxieHolding
                                                        axieId={item?.id}
                                                        axieName={item?.name}
                                                        axieClass={item?.class}
                                                        axieImage={item?.image}
                                                        axieBreedCount={item?.breedCount}
                                                    />
                                                </a>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </MainLayout>
            </React.Fragment>
        );
    }
}

export default withRouter(MyNftAssets);
