import React, {Component} from 'react';
import {Col, Modal, Row, Space, Typography} from "antd";

interface ILanguageProps {
    activeLanguage?: string;
}

class Language extends Component<ILanguageProps> {
    onClickId = () => {
        Modal.error({
            content: 'Not Yet Available'
        })
    }

    render() {
        return (
            <Row className={`language-center`} justify={'center'}>
                <Col>
                    <Space direction={'horizontal'} className={'footer-language-wrapper'}>
                        <Typography.Link>EN</Typography.Link>
                        <div>|</div>
                        <Typography.Link onClick={this.onClickId}>ID</Typography.Link>
                    </Space>
                </Col>
            </Row>
        );
    }
}

export default Language;
