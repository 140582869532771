import React, {Component} from 'react';
import {Col, Image, Row} from "antd";
import "./About.less";

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Row id={"about-section"} align={'middle'}>
                    <Col xl={12} lg={12} sm={24}>
                        <div id={"about-head"}>
                            Community Own<br/>
                            <span>Project</span>
                        </div>
                        <div id={"about-sub-head"}>
                            We facilitate the community to be able to build together this project to be better
                        </div>
                        <div id={'about-require-container'}>
                            <div id={"require-description"}>
                                We need your contribution skill as
                            </div>
                            <ul id={"require-skill-list"}>
                                <li>
                                    <div>
                                        <Image preview={false} src={'assets/img/tester.PNG'}/>
                                        Product Tester
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <Image preview={false} src={'assets/img/designer.PNG'}/>
                                        Designer
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <Image preview={false} src={'assets/img/programer.PNG'}/>
                                        Programmer
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} id={"about-image"}>
                        <Image preview={false} src={'assets/img/coin_2.PNG'}
                               alt={`n3ft-assets`}/>
                    </Col>
                </Row>
            </React.Fragment>
        )
            ;
    }
}

export default About;