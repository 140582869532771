import React from 'react';
import {Pie} from '@ant-design/plots';
import {PieConfig} from "@ant-design/charts";
import "./Tokenomic.less"

const TokenomicPie = () => {
    const data = [
        {
            type: 'Orginator',
            value: 5,
        },
        {
            type: 'Project Leader',
            value: 5,
        },
        {
            type: 'Art Manager',
            value: 3,
        },
        {
            type: 'Early Birds',
            value: 25,
        },
        {
            type: 'Contributors',
            value: 62,
        },
    ];
    const config: PieConfig = {
        data,
        angleField: 'value',
        colorField: 'type',
        color: [
            "#FF6B3B",
            "#626681",
            "#FFC100",
            "#9FB40F",
            "#76523B",
            "#DAD5B5",
            "#0E8E89",
            "#E19348",
            "#F383A2",
            "#247FEA"
        ],
        label: {
            type: 'inner',
            content: ({percent}) => `${(percent * 100).toFixed(0)}%`,
            position: "bottom",
            offset: '-30%',
            style: {
                fill: "#FFF",
                fontSize: 19,
                fontWeight: "bolder",
            }
        },
        legend: {
            position: "bottom",
            flipPage: false,
            itemName: {
                style: {
                    fill: "#FFF",
                    fontSize: 19,
                    fontWeight: "bolder",
                }
            },
            animate: true,
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],

        pieStyle: {
            lineWidth: 0,
        },

        radius: 0.8,

        className: 'pie-chart',

    };
    return <React.Fragment>
        <Pie {...config} />
    </React.Fragment>;
};

export default TokenomicPie;