import axios from "axios";

class RoninChainService {
    private axios = axios.create({
        // baseURL: "https://explorer.roninchain.com/address/ronin:6462ce6073437557ef1d28b0f64664a99b4220da/token-holdings",
        // baseURL: "https://game-api.skymavis.com/game-api/leaderboard?client_id=0xabb2438d9d8acf260a553527f79402365be1257f&limit=1",
        baseURL: "https://game-api.axie.technology/logs/pvp/0xabb2438d9d8acf260a553527f79402365be1257f",
    });

    public async getTokenHoldings(params?: { roninAddress?: string }) {
        try {
            const dataChain = await this.axios.get<any>(``, {
                headers: {
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Content-Type': 'application/json',

                    'authority': "game-api.skymavis.com",
                },
                method: 'get',
            });

            console.log(dataChain);

        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }
}


export const roninChainService = new RoninChainService();
