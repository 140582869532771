import MediaQuery from "react-responsive";


export const IsDesktopOrLaptop: React.FC = (props) => {
    return <MediaQuery minWidth={1224}>
        {props.children}
    </MediaQuery>
}

export const IsBigScreen: React.FC = (props) => {
    return <MediaQuery minWidth={1824}>
        {props.children}
    </MediaQuery>
}
export const IsTabletOrMobile: React.FC = (props) => {
    return <MediaQuery maxWidth={1224}>
        {props.children}
    </MediaQuery>
}

export const IsMobile: React.FC = (props) => {
    return <MediaQuery maxWidth={768}>
        {props.children}
    </MediaQuery>
}

export const IsPortrait: React.FC = (props) => {
    return <MediaQuery orientation={'portrait'}>
        {props.children}
    </MediaQuery>
}
export const IsRetina: React.FC = (props) => {
    return <MediaQuery minResolution={'2ddpx'}>
        {props.children}
    </MediaQuery>
}
