import React, {Component} from 'react';
import {Button, Col, Image, Row} from "antd";
import "./Games.less";

class Games extends Component {

    imageList = (params: { img?: string; isAvailable: boolean; gameName: string; }) => {
        return <React.Fragment>
            <Image className={!params.isAvailable ? "blur" : ""} preview={false}
                   src={params.img ?? "https://dummyimage.com/600x400/000/fff"} alt={"axie"}/>
            <div className={"game-title"}>
                {params.gameName}
            </div>
            <div className={"game-availability-status"}>
                {!params.isAvailable && <span className={"coming-soon-text"}>Coming Soon</span>}
            </div>
        </React.Fragment>
    }

    render() {
        return (
            <React.Fragment>
                <Row id={"games-section"} align={'middle'}>
                    <Col xs={24} xl={24}>
                        <div id={"title"}>
                            GAMES YOU CAN TRACK
                        </div>
                        <Row gutter={[45, 45]} id={"game-list-wrapper"}>
                            <Col xl={6} sm={12} lg={6} xs={12}>
                                {this.imageList({
                                    gameName: "Axie Infinity",
                                    isAvailable: true,
                                    img: 'assets/img/afkgaming_2021-07_1b98241e-ef6c-41a9-8593-656d27c77c85_axie_cover.jpg'
                                })}
                            </Col>
                            <Col xl={6} sm={12} lg={6} xs={12}>
                                {this.imageList({
                                    gameName: "Thetan Arena",
                                    isAvailable: false,
                                    img: 'assets/img/20211205_140425.jpg'
                                })}
                            </Col>
                            <Col xl={6} sm={12} lg={6} xs={12}>
                                {this.imageList({
                                    gameName: "The Sandbox",
                                    isAvailable: false,
                                    img: 'assets/img/the-sandbox.png'
                                })}
                            </Col>
                            <Col xl={6} sm={12} lg={6} xs={12}>
                                {this.imageList({
                                    gameName: "Pegaxy", isAvailable: false,
                                    img: 'assets/img/pegaxy.png'
                                })}
                            </Col>
                        </Row>
                        <Row justify={'center'} style={{marginTop: "50px"}}>
                            <Col>
                                <div id={"bottom-text"}>Managing a diverse NFT Game Asset can be challenging,<br/>N3FT.games
                                    is here to help you.
                                </div>
                            </Col>
                        </Row>
                        <Row justify={'center'} style={{marginTop: "25px"}}>
                            <Col>
                                <Button onClick={event => window.location.href = "https://app.n3ft.games/"}
                                        id={'games-signup-button'}>TRY IT NOW</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Games;
