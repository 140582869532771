import React, {FunctionComponent} from "react";
import {Col, Row, Tag} from "antd";
import {EyeInvisibleOutlined} from "@ant-design/icons";

export const TokenHoldingVertical: FunctionComponent<any> = (props: any) => {
    return (
        <div className={`boxListProduct`}>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/ron.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 1.02 RON</span><br/>
                        <span className={`nftValueConvert`}>~ 1.02 USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/axs.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 1.02 AXS</span><br/>
                        <span className={`nftValueConvert`}>~ 1.02 USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/slp.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 1.02 SLP</span><br/>
                        <span className={`nftValueConvert`}>~ 1.02 USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/usdc.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 1.02 USDC</span><br/>
                        <span className={`nftValueConvert`}>~ 1.02 USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/eth.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 1.02 WETH</span><br/>
                        <span className={`nftValueConvert`}>~ 1.02 USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/axie.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> 10 AXIE</span><br/>
                        <span className={`nftValueConvert`}>
                            <Tag icon={<EyeInvisibleOutlined/>} color="#404950" style={{marginTop: 5}}>
                                JoinUs
                            </Tag>
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
