import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from './redux';
import MainLanding from "./screen/MainLanding/MainLanding";
import "./App.less";
import SignUp from "./screen/SignUp/SignUp";
import SignIn from "./screen/SignIn/SignIn";
import Guest from "./screen/Axieinfinity/Guest";
import GuestTracker from "./screen/Axieinfinity/GuestTracker";
import MyNftAssets from "./screen/Axieinfinity/MyNftAssets";

function App() {
    return (
        <Provider store={store}>
            <Routes>
                <Route path={"/"} element={<MainLanding/>}/>
                <Route path={"/sign-up"} element={<SignUp/>}/>
                <Route path={"/sign-in"} element={<SignIn/>}/>
                <Route path={"/guest"} element={<Guest/>}/>
                <Route path={"/axieinfinity/guest/tracker/:roninAddress"} element={<GuestTracker/>}/>
                <Route path={"/axieinfinity/my-assets/:roninAddress"} element={<MyNftAssets/>}/>
            </Routes>
        </Provider>
    );
}

export default App;
