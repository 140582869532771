import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import TrackerLayout from "../../components/TrackerLayout/TrackerLayout";
import {Col, Row} from "antd";
import {CardAxie} from "../../components/Tracker/CardAxie";
import {axieDataService} from "../../services/axieinfinity/AxieDataService";

interface IParams {
    roninAddress: string;
}

interface IGuestTrackerStates {
    processing: boolean;
    roninAddress: string;
    dataAxie: any;
}

type Props = WithRouterProps<IParams>;

class GuestTracker extends Component<Props, IGuestTrackerStates> {
    private readonly initState!: IGuestTrackerStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;

        this.initState = {
            processing: true,
            roninAddress: match.params.roninAddress,
            dataAxie: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        try {
            await axieDataService.getListAxieByAddress({
                roninAddress: this.state?.roninAddress
            }).then((r) => {
                this.setState({
                    processing: false,
                    dataAxie: r?.data
                })
            });
        } catch (e) {
            console.log(e)
        }
    }

    render() {

        console.log(this.state.dataAxie)

        return (
            <React.Fragment>
                <TrackerLayout defaultSelectedKeys={['m-tracker']}>
                    <Row>
                        {
                            this.state.dataAxie.map((item: any, i: number) =>
                                <Col xs={24} xl={6} className={`pad-20`}>
                                    <CardAxie
                                        axieImage={item?.image}
                                        axieTotalAttack={0}
                                        axieTotalDefend={0}
                                        axieTotalEnergy={0}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </TrackerLayout>
            </React.Fragment>
        );
    }
}

export default withRouter(GuestTracker);
