import React, {FunctionComponent} from "react";
import "./CardAxie.css";

export const CardAxie: FunctionComponent<any> = (props: any) => {
    return (
        <div style={{paddingTop:"50px"}}>
            <div className={`card-product`}>
                <img src={props?.axieImage} className={`axie-image`} alt="AxieImage"/>

                <div className={`axie-resume`}>
                    <img src={`/assets/axieinfinity/def.png`} className={`logo-defend`} alt="LogoDefender"/> {props?.axieTotalAttack}
                    <img src={`/assets/axieinfinity/atk.png`} className={`logo-attack`} alt="LogoAttack"/> {props?.axieTotalDefend}
                    <img src={`/assets/axieinfinity/nrg.png`} className={`logo-energy`} alt="LogoEnergy"/> {props?.axieTotalEnergy}
                </div>

                <div className={`card-detail`}>
                    Under Construct
                </div>
            </div>
        </div>
    )
}
