import React, {Component} from 'react';
import {Button, Col, Drawer, Menu, Row} from 'antd';
import "./MainLayout.css";
import {MenuOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class MainLayout extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            collapsed: false,
            viewClass: 'desktop',
            user: "",
            current: 'mail',
            visible: false
        }
        this.state = {...this.initState}
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    async componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <React.Fragment>
                <div className={`divMenuDesktop`}>
                    <Row>
                        <Col span={12} offset={2}>
                            <Menu mode="horizontal" theme="dark">
                                <Menu.Item key="Logo">
                                    <img className={`header-logo`} src={'../../assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Menu.Item>
                                <Menu.Item key="mail">
                                    <a href="">Home</a>
                                </Menu.Item>
                                <SubMenu key={`sub-1`} title={<span>Menu 1</span>}>
                                    <MenuItemGroup key={`group-1`} title="Item 1">
                                        <Menu.Item key="key-1">Option 1</Menu.Item>
                                        <Menu.Item key="key-2">Option 2</Menu.Item>
                                    </MenuItemGroup>
                                    <MenuItemGroup key={`group-2`} title="Item 2">
                                        <Menu.Item key="key-3">Option 3</Menu.Item>
                                        <Menu.Item key="key-4">Option 4</Menu.Item>
                                    </MenuItemGroup>
                                </SubMenu>
                                <Menu.Item key="alipay">
                                    <a href="">Menu 2</a>
                                </Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                </div>

                <div className={`divMenuMobile`}>
                    <Row>
                        <Col span={12} offset={2}>
                            <Menu mode="horizontal" theme="dark">
                                <Menu.Item key="Logo">
                                    <img className={`header-logo`} src={'../../assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col span={8}>
                            <div className={`floatRight`} style={{paddingTop: "7px"}}>
                                <Button onClick={this.showDrawer} type="default" icon={<MenuOutlined/>}/>
                            </div>
                        </Col>
                    </Row>
                    <Drawer
                        title="Menu"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <div>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                theme="dark"
                                inlineCollapsed={this.state.collapsed}
                            >
                                <Menu.Item key="1">
                                    Menu 1
                                </Menu.Item>
                            </Menu>
                        </div>
                    </Drawer>
                </div>

                <Content className={`mainContent`} style={{minHeight: '100vh'}}>
                    <Row>
                        <Col xxl={{span: 17, offset: 4}} xl={{span: 20, offset: 2}}>
                            <div>{this.props.children}</div>
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
        );
    }
}

export default MainLayout;

