import React, {Component} from 'react';
import "./TrackerLayout.css";
import {Layout, Menu} from "antd";
import {MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, UndoOutlined, QrcodeOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";

const {Header, Sider, Content} = Layout;

class TrackerLayout extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            collapsed: false,
            viewClass: 'desktop',
            user: "",
        }
        this.state = {...this.initState}
    }

    updateDimensions = () => {
        if (window.innerWidth < 1080) {
            this.setState({
                collapsed: true,
                viewClass: 'mobile',
            });
        } else {
            this.setState({
                collapsed: false,
                viewClass: 'desktop',
            });
        }
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    async componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <Layout>
                <Sider className={`site-layout-sider`} trigger={null} collapsible collapsed={this.state.collapsed}>
                    <div style={{paddingBottom: 20}}>
                        <img src="/assets/img/favicon.png" style={{marginTop:"25px", display: "block", marginLeft: "auto", marginRight: "auto"}} alt="Logo"/>
                    </div>

                    <Menu
                        defaultSelectedKeys={this.props.defaultSelectedKeys}
                        defaultOpenKeys={this.props.defaultOpenKeys}
                        mode="inline"
                        theme="dark"
                        // inlineCollapsed={this.state.collapsed}
                    >
                        <Menu.Item key="m-dashboard" icon={<HomeOutlined/>}><Link to={`/dashboard`}>Dashboard</Link></Menu.Item>
                        <Menu.Item key="m-tracker" icon={<QrcodeOutlined/>}><Link to={`/dashboard`}>Tracker</Link></Menu.Item>
                    </Menu>

                </Sider>
                <Layout className="site-layout-content">
                    <Header className="site-layout-background">
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: this.toggle,
                        })}
                        <span style={{marginLeft:"40px", color:"#fff", fontSize:"150%"}}>My NFT Assets</span>

                        <Link to={`/guest`}>
                            <UndoOutlined style={{margin:"20px", color:"#fff", fontSize:"150%", cursor:"pointer", float:"right"}} />
                        </Link>

                    </Header>
                    <Content style={this.props.noPadNoMar ? {minHeight: '100vh'} : {minHeight: '100vh'}}>
                        <br/>
                        <div>{this.props.children}</div>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default TrackerLayout;

