import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button, Col, Form, Input, Layout, Row, Typography} from "antd";
import "./Guest.less";
import Language from "../../components/Language/Language";

class Guest extends Component {
    render() {
        return (
            <Layout>
                <Layout.Content>
                    <div id={"sign-up-body"}>
                        <Row id="sign-up-wrapper">
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-left-side"}>

                                <Link to={`/`}>
                                    <img className={`header-logo`} src={'assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Link>

                                <div id={"text-left-side-wrapper"}>
                                    <Typography.Title level={1}>
                                        Track your Axieinfinity asset.
                                    </Typography.Title>
                                </div><br/>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-right-side"}>
                                <div id={"form-wrapper"}>
                                    <Typography.Title level={2}>
                                        Axieinfinity Guest
                                    </Typography.Title>

                                    <Form layout={'vertical'} size={'large'}>
                                        <Row gutter={[16, 0]}>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Your Ronin Address'}>
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button className={'signup-button'}>
                                                    Track
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
                <Layout.Footer className={'main-footer'}>
                    <Language/>
                </Layout.Footer>
            </Layout>
        );
    }
}

export default Guest;
