import React, {FunctionComponent} from "react";
import {Col, Row, Tag} from "antd";
import {EyeInvisibleOutlined} from "@ant-design/icons";
import classConfig from "../../config/axieinfinity/classConfig";

export const AxieHolding: FunctionComponent<any> = (props: any) => {
    const classBackgroundColor = classConfig[props?.axieClass]?.color;
    const classWhiteLogo = classConfig[props?.axieClass]?.logo_white;
    return (
        <div>
            <img src={props?.axieImage} className={`nftImage`} alt="NftImage"/>
            <span className={`axieClassTag`} style={{backgroundColor: classBackgroundColor}}><img src={'../../assets/' + classWhiteLogo} alt="NftImage" style={{height: 12, marginTop: -3}}/> #{props?.axieId}</span>
            <div style={{marginBottom: 5}}/>
            <span className={`textWhite90`}>{props?.axieName}</span><br/>
            <span className={`textGrey80`}>Breed Count: {props?.axieBreedCount}</span>

            <hr className={`hrGrey`}/>

            <Row>
                <Col span={12}>
                    <span className={`textGrey80`}>Purchase Price:</span><br/>
                    <Tag icon={<EyeInvisibleOutlined/>} color="#404950" style={{marginTop: 5}}>
                        SignIn
                    </Tag>
                </Col>
                <Col span={12}>
                    <span className={`textGrey80`}>Estimated Price:</span><br/>
                    <Tag icon={<EyeInvisibleOutlined/>} color="#404950" style={{marginTop: 5}}>
                        JoinUs
                    </Tag>
                </Col>
            </Row>


        </div>
    )
}
